<template>
    <div class="container-page">

      <!-- banner -->
      <header-component-index :bannerImg="productBannerImg">
        <div class="product-slogan">
          <div class="img animate__animated animate__fadeInLeft">
            <img :src="productSloganImg">
          </div>
          <div class="product-slogan-info">
            <div class="txt animate__animated animate__bounceInDown">贵州石头科技</div>
            <div class="tit animate__animated animate__fadeInRight">自主研发智能设备</div>
            <div class="txt animate__animated animate__fadeInRight" style="margin-top: 20px">Intelligent device</div>
            <div class="prompt animate__animated animate__bounceInDown">让生活，多一种选择</div>
            <a href="/about" class="animate__animated animate__bounceInUp" title="">联系我们</a>
          </div>
        </div>
      </header-component-index>

      <div class="section-page intellect-product">
        <div class="container">
          <h3 class="h3">智能产品</h3>
          <p class="sub-tit">Intelligent products</p>

          <div class="intellect-product-list">
            <div class="tabs-head">
              <div class="arrow-circle left-arrow-circle" @click="handleArrowCircle('left')">
                <img :src="leftCircleImg">
              </div>
              <div class="tabs-head-inner" id="tabs_head">
                <div class="grid" :class="{active:currentIndex==index}" v-for="(item,index) of productList" :key="index" @click="handleTabs(item,index)">
                  <div class="img">
                    <img :src="item.img">
                  </div>
                  <p class="txt">{{item.name}}</p>
                </div>
              </div>
              <div class="arrow-circle right-arrow-circle" @click="handleArrowCircle('right')">
                <img :src="rightCircleImg">
              </div>
            </div>
            <div class="tabs-content" v-html="tabsContentHtml">

            </div>
          </div>


        </div>
      </div>



      <!-- 公司地址 -->
      <address-component-page-index></address-component-page-index>
      <!-- 合作与了解 -->
      <contact-component-index></contact-component-index>

      <!-- 底部 -->
      <footer-component-index></footer-component-index>

    </div>
</template>

<script>
  import HeaderComponentIndex from "../components/header";
  import ContactComponentIndex from "../components/contact";
  import FooterComponentIndex from "../components/footer";
  import AddressComponentPageIndex from "../components/address";

  // assets

  import productBannerImg from '@/assets/banner-product.png'
  import productSloganImg from '@/assets/product-slogan.png'

  import tabsHd01Img from '@/assets/tabs-hd01.png'
  import tabsHd02Img from '@/assets/tabs-hd02.png'
  import tabsHd03Img from '@/assets/tabs-hd03.png'
  import tabsHd04Img from '@/assets/tabs-hd04.png'
  import tabsHd05Img from '@/assets/tabs-hd05.png'
  import tabsHd06Img from '@/assets/tabs-hd06.png'
  import tabsHd07Img from '@/assets/tabs-hd07.png'
  import tabsHd08Img from '@/assets/tabs-hd08.png'
  import tabsHd09Img from '@/assets/tabs-hd09.png'
  import tabsHd10Img from '@/assets/tabs-hd10.png'
  import tabsHd11Img from '@/assets/tabs-hd11.png'
  import tabsHd12Img from '@/assets/tabs-hd12.png'

  import leftCircleImg from '@/assets/left-circle.png'
  import rightCircleImg from '@/assets/right-circle.png'

    export default {
        name: "productPageIndex",
        components: {
          AddressComponentPageIndex,

          FooterComponentIndex,
          ContactComponentIndex,
          HeaderComponentIndex,
        },
        data() {
          return {
            productBannerImg,productSloganImg,
            leftCircleImg,rightCircleImg,
            tabsHd01Img,tabsHd02Img,tabsHd03Img,tabsHd04Img,tabsHd05Img,tabsHd06Img,tabsHd07Img,tabsHd08Img,tabsHd09Img,tabsHd10Img,tabsHd11Img,tabsHd12Img,
            productList:[
              {name:'酒店物联网全景系统',img:tabsHd01Img,
                html:`<strong style="display:block;margin: 30px 0">酒店物联网全景化系统</strong><p>ST 1002 是基于大数据平台的控制系统涉及区域智能化管理与控制技术领域；为了提高区域管理的一体化、智能化程度；智能主机可通过人体红外检 测传感器、环境温湿度检测传感器、房间空气质量检测传感器、电流电压检测传感器、电表数据传感器和门锁状态检测传感器传递的数据进行区域环 境状态的监控，适用酒店、家庭、楼宇等多种应用环境的多功能综合控制系统，它集电流数据采集、电压数据采集、环境数据采集、智能门锁、无卡 取电、灯光控制、窗帘控制、红外遥控转发、传感器输入控制、智能门铃、人脸识别等功能于一体，可以灵活实现节能、门禁、环境监控、智能照明 等多项功能的全部或者任意一项或者多项功能组合，通过CAN总线把数据采集模块、智能门锁、智能控制面板、窗帘电机等连接到核心模块并且通过 以太网方式将数据传输到监控中心。
                       <p>本系统通过手机APP操作，可以实现远程开灯、远程数据监控、主动推送报警信息等。</p>
                       <img src="${tabsHd01Img}" style="display: block;margin: 30px auto ;">
                       <div class="">1.1产品特点
                       <p>1.32位ARM高效处理器 </p>
                       <p>2.集保护、测量、信号、采集、控制、报警等功能于一体。</p>
                       <p>3.智能主机：提供RS485、CAN总线及网络接口有很好的扩展性。</p>
                       <p>4.灯光控制扩展灵活：提供5路开关接口的输出及每路开关电流采集，开关可以拼，最多扩展到56路扩展灵活。</p>
                       <p>5.数据采集全面：火警、煤气泄漏、甲醛超标、PM2.5、温度、湿度、门禁、烟雾、一氧化碳等数据的采集。</p>
                       <p>6.告警联动定义灵活：可将任意一个输入的告警调用任意模式输出。</p>
                       <p>7.传感器输入简单：支持8路开关输入采集。</p>
                       <p>8.升级功能方便：提供设备在线升级（局域网升级）和远程升级功能、。</p>
                       <p>9.模式灵活：提供24个模式每个模式可以编辑调用主机所有输出输入进行管理。</p>
                       <p>10.体积小：安装方便、机箱体小。</p>
                       <p>11.接线方便：产品所有的弱电通过连接全部所有标准网线连接，无需详细了解接线方法，施工简单。</p>
                       <p>12.数据统一：所有数据集中上传到中心平台。</p>

                       <p>1.2使用环境</p>
                       <p>环境温度：-15℃～+70℃，</p>
                       <p>贮存温度：-15℃～+70℃，在极限值下不施加激励量，装置不出现可逆变化，温度恢复后，装置应能正常工作；</p>
                       <p>相对湿度：不超过95％；</p>
                       <p>大气压力：70kPa～106kPa；</p>
                       <p>环境：无振动、无尘埃、无腐蚀性气体、可燃性天气、水蒸气、水滴或盐分等；</p>
                       <p>使用地点应具有防御雨、雪、风、沙、灰的设施。</p>
                       <p>冷却方式：自然冷却。 </p>
                       <p>1.3 工作条件</p>
                       <p>a) 工作温度：-15℃～+70℃</p>
                       <p>b) 相对湿度：不超过95％；</p>
                       <p>c) 海拔高度：<= 5000M</p>
                       <p>d) 电源输入交流220V（电压范围165V-265V）</p>
                       <p>e) 系统可靠接地：接地阻抗<4欧姆。</p>
                       <p>1.4 主要用途及使用范围</p>
                      </div>`},

              {name:'智能门锁',img:tabsHd02Img,
                html:`<strong style="display:block;margin: 30px 0">功能介绍</strong>
                        <p>PVC外壳加钢化玻璃，门铃摄像头，门铃、房号、LOGO，请即打扫、请勿打扰灯光颜色可定义白色、红色，有人模式和无人模式可定义LED灯是否开启</p>
                        <strong style="display:block;margin: 20px 0">主要参数</strong>
                        <p>标准86底盒安装</p>
                                                `},
              {name:'智能设备控制面板',img:tabsHd03Img,
                html:`<strong style="display:block; margin:30px 0;">功能介绍</strong>
                    <p>语音控制酒店所以智能产品，包括控制灯光、窗帘、电视、空调、情景模式等，支持红外</p>
                    <p>同时可以手动开关，拨打电话、开关电视、空调、灯光模式、支持播放音乐、蓝牙连接等</p>
                    <strong style="display:block; margin:30px 0;">主要参数</strong>
                    <p>电源12V 电流202mA  </p>
                    <p>开孔双标准86底盒</p>
                    `},
              {name:'智能主机',img:tabsHd04Img,
                html:`<strong style="display:block; margin:30px 0;">功能介绍</strong>
                    <p>客控主机参数:</p>
                    <p>主机芯片采用美国意法半导体32位ARM处理器，内置高性能实时操作系统FREERTOS，确保系统稳健运行；</p>
                    <p>采用RM架构</p>
                    <p>14路10A继电器（灯光）（强电）</p>
                    <p>5路10A继电器（空调）（强电）</p>
                    <p>1路30A继电器（受控插座）（强电）</p>
                    <p>2路可控硅调光（强电）</p>
                    <p>4路电动窗帘（专用电动窗帘回路）</p>
                    <p>40路弱电干接点输出（弱电）</p>
                    <p>40路弱电干接点输入（弱电）</p>
                    <p>1路门铃输出端子（弱电）</p>
                    <p>3路485端口输出（弱电）</p>
                    <p>TCP/IP组网，网络端口（弱电）</p>
                    <strong style="display:block; margin:30px 0;">主要参数</strong>
                    <p>客控主机RCU具有功能</p>
                    <p>1.具备微信控制及微信语音控制房间灯光</p>
                    <p>2.具有网络管理功能，可通过前台对每个房间进行灯光及空调的控制，具有定时功能。（可以在客人入睡后，在前台管理系统上把空调温度调节到标准22-24度，同时控制空调风速，节约能源）</p>
                    <p>3.具有能源管理监控功能，可分析一个季度或某段时间，房间的能源能耗分析图表</p>
                    <p>4.前台管理电脑可以通过系统管理软件，对每一间入住的客房的灯光设备进行实时监控，前台可以在客人入睡后，对空调温度及风速进行调节，关闭灯光等，从而节约能源。</p>
                    `},
              {name:'二合一传感器',img:tabsHd05Img,
                html:`<strong style="display:block; margin:30px 0;">功能介绍</strong>
                    <p>材质：PVC防火材料</p>
                    <p>功能：检测房间是否有人，红外转发控制电视、空调以及灯光模式；</p>
                    <p>安装方式：吸顶安装</p>
                    <p>尺寸：直径105mm</p>
                    <p>布线数量：（拉1根网线至RCU）</p>
                    <strong style="display:block; margin:30px 0;">主要参数</strong>
                    <p>工作电压12V DC</p>
                    <p>探测距离8M</p>
                    <p>负载功率80W</p>
                    <p>检测角度180度</p>
                    <p>开孔尺寸75x75mm</p>
                    `},
              {name:'红外传感器',img:tabsHd06Img,
                html:`<strong style="display:block; margin:30px 0;">功能介绍</strong>
                    <p>材质：PVC防火材料</p>
                    <p>功能：客人进洗手间自动开灯，离开洗手间自动关灯，延时3分钟后关闭灯光；</p>
                    <p>安装方式：吸顶安装</p>
                    <p>尺寸：直径105mm</p>
                    <p>布线数量：（拉1根网线至RCU）</p>
                    <strong style="display:block; margin:30px 0;">主要参数</strong>
                    <p>工作电压12V DC</p>
                    <p>探测距离8M</p>
                    <p>负载功率80W</p>
                    <p> 光控设置3Iux~200Iux</p>
                    <p>检测角度 140度</p>
                    <p>开孔尺寸42x45mm</p>
                    `},
              {name:'智能门锁',img:tabsHd07Img,
                html:`<strong style="display:block;margin: 30px 0">功能介绍</strong>
                        <p>1、开门方式：IC卡、手机app、机械钥匙；</p>
                        <p>2、安全因素：严禁一张房卡可开多个房间情况；</p>
                        <p>3、系统对接：PMS系统对接门锁系统，实现PMS系统直接制房卡操作；</p>
                        <p>4、低电量预警：电池电量不足自动报警，及时更换，避免客人入住打不开；</p>
                        <p>5、IC卡、手机ap权限：应在PMS系统受权时间范围内开启门锁</p>
                        <p>6、软件可通过权限方式进行制作手机app/IC(总卡、领班卡、楼层卡),功能。便于客房管理控制。</p>
                        <strong style="display:block;margin: 20px 0">主要参数</strong>
                        <p>门锁参数</p>
                        <p>1、  锁体重量：2.8kg</p>
                        <p>2、  锁体外形尺寸：78×360mm，</p>
                        <p>3、  锁芯外形尺寸：90×149mm</p>
                        <p>4、  工作电源：直流6V，四节1.5V碱性电池，可开锁20000次以上</p>
                        <p>5、 报警电压：电池电压≤4.5时会自动报警，报警后仍能开锁100次以上</p>
                        <p>6、  静态功耗：感应卡锁＜100μA</p>
                        <p>7、  动态功耗： 感应卡锁＜120mA</p>
                        <p>8、  工作温度：-10℃～+65℃</p>
                        <p>9、  静电抗性：大于15000V</p>
                        <p>10、 开锁记录：800条</p>
                        <p>11、 卡型号：复旦S50卡</p>
                        <p>12、 卡工作频率：13.56兆</p>
                        <p>13、 手机通信方式：蓝牙</p>
                                                `},
              {name:'床头开关2位带五孔USB',img:tabsHd08Img,
                html:`<strong style="display:block; margin:30px 0;">功能介绍</strong>
                    <p>1、环保阻燃PC材质</p>
                    <p>2、可以自定义场景控制灯光、窗帘、模式等</p>
                    <p>3、支持app/小程序远程控制开关</p>
                    <p>4、支持创建场景开关灯，柔和模式、支持双控功能</p>
                    <p>5、支持丝印定制</p>
                    <p>6、环保阻燃PC，清脆按键</p>
                    <p>7、5孔USB插座，最大输出10A</p>
                    <p>8、配2个USB口，支持5V/2A输出，快速充电</p>
                    <strong style="display:block; margin:30px 0;">主要参数</strong>
                    <p>电源参数:100-242V~, 50/60Hz</p>
                    <p>协议:Zigbee3.0</p>
                    <p>待机功耗：≤1W</p>
                    <p>材质:PC</p>
                    <p>开关路数:8路</p>
                    <p>最大阻性负载：单路/总路数≤1000W</p>
                    <p>最大容性/感性负载:单路≤200W</p>
                    `},
              {name:'门磁',img:tabsHd09Img,
                html:`<strong style="display:block; margin:30px 0;">功能介绍</strong>
                    <p></p>
                    <strong style="display:block; margin:30px 0;">主要参数</strong>
                    <p>工作环境：-10℃~50℃</p>
                    <p>相对湿度：≤90%</p>
                    <p>磁铁尺寸：46*19*9mm</p>
                    `},
              {name:'窗帘开关控制器',img:tabsHd10Img,
                html:`<strong style="display:block; margin:30px 0;">功能介绍</strong>
                    <p>电源12V 电流200mA 功能：把网络数据转为窗帘控制数据 </p>
                    <strong style="display:block; margin:30px 0;">主要参数</strong>
                    <p>窗帘开关控制器</p>
                    `},
              {name:'窗帘电机',img:tabsHd11Img,
                html:`<strong style="display:block; margin:30px 0;">功能介绍</strong>
                    <p>语音，智能开关进行操作，实现窗帘自动开启和关闭，也可以手动开启和关闭；通过手机可以观察窗帘的开启、关闭状态。</p>
                    <strong style="display:block; margin:30px 0;">主要参数</strong>
                    <p>常规窗帘电机 </p>
                    `},
              {name:'窗帘轨道',img:tabsHd12Img,
                html:`<strong style="display:block; margin:30px 0;">功能介绍</strong>
                    <p>传动箱主体塑料件采用进口聚甲醛辞职传动齿轮上下双轴承，更顺滑，更耐用金属件采用304不锈钢，表面镜面处理，美观、坚固</p>
                    <strong style="display:block; margin:30px 0;">主要参数</strong>
                    <p>常规安装</p>
                    `},
            ],
            tabsContentHtml:`<p></p>`,
            currentIndex:0,

            parentTabLength:0,
          }
        },
        mounted() {
          // 默认第一个选中
          this.currentIndex = 0
          this.tabsContentHtml  = this.productList[0].html
        },
        methods: {
          // 点击切换tabs
          handleTabs(item,index){
            this.currentIndex = index
            this.tabsContentHtml = item.html
          },

          // 产品 左右切换 type left 左切换  right 右切换
          handleArrowCircle(type){
            let tempDistance = 0
            let headWidth = document.getElementById('tabs_head').offsetWidth
            let headTabNum = parseInt(headWidth/250)
            if(type=='left'){
                if(this.parentTabLength>0){
                  this.parentTabLength -= 1;
                }else {
                  this.parentTabLength =0;
                  return
                }
            }else if(type=='right'){
                if(this.parentTabLength < this.productList.length-headTabNum){
                  this.parentTabLength += 1;
                }else {
                  this.parentTabLength =this.productList.length-headTabNum;
                  return
                }
            }

            tempDistance = this.parentTabLength*250
            document.getElementById('tabs_head').scrollLeft = tempDistance
          },

        }
    }
</script>

<style lang="scss" scoped>

  .product-slogan{
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    .product-slogan-info{
      display: flex;
      flex-direction: column;
      text-align: left;
      .txt{
        margin-top: 100px;
        font-size: 36px;
        color: #fff;
      }
      .tit{
        margin-top: 20px;
        font-size: 60px;
        color: #fff;
      }
      .prompt{
        margin-top: 30px;
        font-size: 21px;
        color: #FFF
      }
      a{
        margin-top: 100px;
        text-align: center;
        display: inline-block;
        text-decoration: none;
        width: 140px;
        height: 64px;
        line-height: 64px;
        background: #FFFFFF;
        border-radius: 6px;
        font-size: 18px;
        color: #3D67F1;
        transition: all 1s ease;
        &:hover{
          width: 180px;
          transition: width .3s ease
        }
      }
    }
    .img{

    }
  }

  .intellect-product-list{
    margin-top: 65px;
    .tabs-head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .arrow-circle{
        width: 48px;
        margin-top: -65px;
        cursor: pointer;
      }
      .tabs-head-inner{
        flex: 1;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        overflow: hidden;
        overflow-x: auto;

        &::-webkit-scrollbar-track{
          -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
          border-radius: 6px;
          background-color: #eee;
        }
        &::-webkit-scrollbar{
          height: 8px;
          background-color: #eee;
        }

        &::-webkit-scrollbar-thumb{
          border-radius: 6px;
          -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.3);
          background-color: #ccc;
        }

        .grid{
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 200px;
          margin-right: 50px;
          margin-bottom: 30px;
          cursor: pointer;
          .img{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 200px;
            background: #F8FAFD;
            box-shadow: 0px 2px 10px 3px rgba(227, 235, 248, 0.89);
            border: 4px solid #F8FAFD;
            border-radius: 12px;
            overflow: hidden;
          }
          .txt{
            margin-top: 20px;
            font-size: 21px;
            font-weight: bold;
            color: #252440;
          }
          &.active{
            .img{
              box-shadow: 0px 2px 10px 3px rgba(61, 103, 241, 0.29);
              border: 4px solid #3D67F1;
            }
          }
          &:first-child{
            margin-left: 20px;
          }
          &:last-child{
            margin-right: 20px;
          }
        }
      }

    }
    .tabs-content{
      padding: 100px 0 50px;
      text-align: left;
      font-size: 18px;
      color: #252440;
      line-height: 35px;
    }
  }


  @media screen and (min-width: 768px) and (max-width: 1080px) {
    .product-slogan {
      padding-top: 40px;
      .img{
        width: 50%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .product-slogan-info {
        .txt {
          font-size: 22px;
          margin-top: 30px;
        }
        .tit{
          font-size: 34px;
        }
        .txt{
          font-size: 18px;
        }
        .prompt{
          font-size: 18px;
        }
        a{
          margin-top: 50px;
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .product-slogan {
      padding-top: 20px;
      .img{
        display: none;
      }
      .product-slogan-info {
        .txt {
          font-size: 18px;
          margin-top: 30px;
        }
        .tit{
          font-size: 24px;
        }
        .txt{
          font-size: 16px;
        }
        .prompt{
          font-size: 16px;
        }
        a{
          margin-top: 50px;
          height: 50px;
          line-height: 50px;
          font-size: 16px;
        }
      }
    }

    .intellect-product-list{
      margin-top: 30px;
      .tabs-head{
        .arrow-circle{
          width: 30px;
          img{
            width: 100%;
          }
        }
      }
      .tabs-content{
        padding: 30px 0 20px;
        font-size: 14px;
        line-height: 30px;
        ::v-deep strong{
          margin: 10px 0 !important;
        }
      }
    }
  }

</style>
